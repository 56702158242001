<ngb-carousel class="carousel-wrapper pt-5 pb-4" *ngIf="post_collection" [wrap]="true" [interval]="60000">
    <ng-template ngbSlide *ngFor="let post of post_collection.posts | shuffle">
        <div class="testimonial-wrapper" *ngIf="post.properties">
            <ng-container *ngIf="post.properties['image']?.asset as image">
                <div class="row">
                    <div class="col-md-4">
                        <p class="text-center">
                            <img class="img-fluid" [src]="cdn.getAssetSmallUrl(image)" [alt]="post.name" />
                        </p>
                        <h5 class="text-center">{{ post.name }}</h5>
                        <h6 class="text-center" *ngIf="post.properties['sub_title']?.value_character as value_character">{{ value_character }}</h6>
                    </div>
                    <div class="col-md-8">
                        <cms-template-section [post]="post" id="d80e4d77-8ba7-42bc-8e26-803434fbd55a"></cms-template-section>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!post.properties['image']?.asset">
                <cms-template-section [post]="post" id="d80e4d77-8ba7-42bc-8e26-803434fbd55a"></cms-template-section>
                <h5>{{ post.name }}</h5>
                <h6 class="text-center" *ngIf="post.properties['sub_title']?.value_character as value_character">{{ value_character }}</h6>
            </ng-container>
        </div>
    </ng-template>
</ngb-carousel>