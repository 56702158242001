<ng-container *ngIf="content$ | async as content">
    <div class="container page-banner dark-banner" *ngIf="content.properties['banner_image']?.asset as banner_image" [style.background-image]="'url('+ cdn.getAssetUrl(banner_image) +')'">
        <div class="row">
            <div class="col-md-7">
                <div class="banner-content">
                    <cms-template-section id="9c9dc10c-b5dd-4c54-82b5-2944196f8bf5"></cms-template-section>	
                    
                    <p *ngIf="content.properties['schedule_page']?.page as schedule_page" class="mt-3">
                        <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call Now</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container bg-white pt-3 pb-5">
        <div class="content-wrapper mt-3">
            <cms-template-section id="18c01e7c-f1c0-49b6-94bf-cf762ab3981a"></cms-template-section>	
    
            <div class="row mt-3 mb-3">
                <div class="col-sm">
                    <cms-template-section id="74d23b29-e817-4244-b8e7-5332fdb4dfa0"></cms-template-section>	
                </div>
                <div class="col-sm">
                    <cms-template-section id="9fda6f8b-f2d2-4eaa-875c-1017d20d23e1"></cms-template-section>	
                </div>
                <div class="col-sm">
                    <cms-template-section id="aa31949e-73c4-4894-9fb0-38d4ce965ac7"></cms-template-section>	
                </div>
                <div class="col-sm">
                    <cms-template-section id="97bcdc83-ff56-485d-b274-c2c5d34ce7f6"></cms-template-section>	
                </div>
                <div class="col-sm">
                    <cms-template-section id="76223e66-1e5b-4e30-b597-de0db6099827"></cms-template-section>	
                </div>
            </div>
            
            <cms-template-section id="fd79dfe5-21a1-4d2d-8b7c-f983d36422bb"></cms-template-section>	
        </div>
    </div>
    <div class="container page-banner dark-banner middle-banner" *ngIf="content.properties['middle_banner_image']?.asset as middle_banner_image" [style.background-image]="'url('+ cdn.getAssetUrl(middle_banner_image) +')'">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-6">
                    <cms-template-section id="37e4be4c-57fe-4c56-be9d-01a09dd9468f"></cms-template-section>	
                </div>
            </div>
        </div>
    </div>
    <div class="container bg-white pt-3 pb-5">
        <div class="content-wrapper mt-3">
            <cms-template-section id="09b27fdf-0b96-42de-a4ca-cafada1f3d05"></cms-template-section>	
        </div>
    
        <cms-post-collection id="c0b0277d-8d95-417a-bf97-c23224e010ca" [component_resolver]="component_resolver"></cms-post-collection>
        
        <div class="content-wrapper mt-3 mb-3">
            <cms-template-section id="286c95b1-b75a-439e-8dc5-7135edbe6cfd"></cms-template-section>	
        </div>
    </div>
</ng-container>