import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CDNService, ContentModel, PageModel } from '@murdough-solutions/cms-common';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { component_resolver } from 'src/app/component-map';
import { MetaService } from 'src/app/modules/core/services/meta.service';

@Component({
    selector: 'CustomPerformanceEnhancementPage',
    templateUrl: './performance-enhancement-page.html',
    styleUrls: ['./performance-enhancement-page.scss']
})
export class CustomPerformanceEnhancementPage implements OnInit {

    public readonly component_resolver = component_resolver

    constructor(
        private readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly cdn: CDNService,
        private readonly meta_service: MetaService,
    ) {

    }

    public readonly page$ = this.route.data.pipe(
        map(({ page_model }) => page_model as PageModel)
    );

    public readonly content$ = this.route.data.pipe(
        map(({ content_model }) => content_model as ContentModel)
    );

    ngOnInit() {
        combineLatest([this.page$, this.content$]).pipe(
            take(1)
        ).subscribe(([page, content]) => {
            const banner_image = content.properties?.banner_image?.asset;

            if (banner_image) {
                this.meta_service.updateMetaTags([
                    { property: 'og:image', content: this.cdn.getAssetSmallUrl(banner_image) }
                ]);
            }
            this.meta_service.InsertSchema({
                '@context': 'https://schema.org/',
                '@type': 'Service',
                name: page.meta_title ?? page.name,
                image: banner_image ? this.cdn.getAssetUrl(banner_image) : null,
                description: page.meta_description,
                url: this.meta_service.buildUrl(page.route),
                provider: {
                    '@type': 'Organization',
                    name: 'Sparks Rowing',
                    sameAs: 'https://sparksrowing.com'
                }
            });


        });
    }

}
