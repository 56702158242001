import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CDNService, ContentModel, PageModel } from '@murdough-solutions/cms-common';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { component_resolver } from 'src/app/component-map';
import { MetaService } from 'src/app/modules/core/services/meta.service';

@Component({
    selector: 'CustomCounselingPage',
    templateUrl: './counseling-page.html',
    styleUrls: ['./counseling-page.scss']
})
export class CustomCounselingPage implements OnInit {

    public readonly component_resolver = component_resolver
    
    constructor(
        private readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly cdn: CDNService,
        private readonly meta_service: MetaService,
    ) {

    }

    public readonly page$ = this.route.data.pipe(
        map(({ page_model }) => page_model as PageModel)
    );

    public readonly content$ = this.route.data.pipe(
        map(({ content_model }) => content_model as ContentModel)
    );

    ngOnInit() {

        combineLatest([this.page$, this.content$]).pipe(
            take(1)
        ).subscribe(([page, content]) => {
            const banner_image = content.properties?.banner_image?.asset;

            if (banner_image) {
                this.meta_service.updateMetaTags([
                    { property: 'og:image', content: this.cdn.getAssetSmallUrl(banner_image) }
                ]);
            }

            this.meta_service.InsertSchema({
                '@context': 'https://schema.org/',
                '@type': 'Service',
                name: page.meta_title ?? page.name,
                image: banner_image ? this.cdn.getAssetUrl(banner_image) : null,
                description: page.meta_description,
                url: this.meta_service.buildUrl(page.route),
                provider: {
                    '@type': 'Organization',
                    name: 'Sparks Rowing',
                    sameAs: 'https://sparksrowing.com'
                }
            });

            this.meta_service.InsertSchema({
                '@context': 'http://schema.org/',
                '@type': 'VideoObject',
                '@id': 'https://player.vimeo.com/video/250534397',
                duration: 'PT1M54S',
                name: page.meta_title ?? page.name,
                thumbnailUrl: 'https://i.vimeocdn.com/video/680684959-30fda3b11e613dd4452bb691f8c0b494dd0c1154f419e7d5138c32d93d083e02-d_640',
                embedUrl: 'https://player.vimeo.com/video/250534397',
                uploadDate: '2018-01-10',
                description: page.meta_description
            });
        });
    }

}
