<ng-container *ngIf="content$ | async as content">
    <div class="container page-banner dark-banner">
        <ng-container *ngIf="content.properties['banner_image']?.asset as banner_image">
            <img class="banner-image d-block d-lg-none" [src]="cdn.getAssetMediumUrl(banner_image)" alt="Be recruited for rowing at college – how Sparks simplifies the college rowing recruiting process." />
            <img class="banner-image d-none d-lg-block" [src]="cdn.getAssetUrl(banner_image)" alt="Be recruited for rowing at college – how Sparks simplifies the college rowing recruiting process." />
        </ng-container>
        <div class="row">
            <div class="col-md-7">
                <div class="banner-content">
                    <cms-template-section id="a609e879-5e08-467e-b5ce-0ea3a55a7ff2"></cms-template-section>
                    
                    <p *ngIf="content.properties['schedule_page']?.page as schedule_page" class="mt-3">
                        <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container bg-white pt-3 pb-5">
        <div class="content-wrapper mt-1">
            <cms-template-section id="82880bb8-809c-4df5-b96c-59c00156b2ac"></cms-template-section>
            
            <div class="ratio ratio-16x9">
                <iframe src="//player.vimeo.com/video/250534397?title=0&amp;amp;byline=0" frameborder="0" allowfullscreen="allowfullscreen" data-mce-fragment="1"></iframe>
            </div>
            
            <cms-template-section id="ee39aee5-b096-4280-a101-dbcd01794974"></cms-template-section>
            
        </div>
        <div class="row mb-4">
            <div class="col-md-7">
                <div class="middle-left-wrapper">
                    <cms-template-section id="631f6112-1d0b-43cf-8e58-9c519effd530"></cms-template-section>
                        
                    <p *ngIf="content.properties['schedule_page']?.page as schedule_page" class="mt-3">
                        <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call Now</a>
                    </p>
                </div>
            </div>
            <div class="col-md-5 middle-right-image">
                <img class="img-fluid" *ngIf="content.properties['middle_right_image']?.asset as middle_right_image" [src]="cdn.getAssetMediumUrl(middle_right_image)" alt="How to get recruited as a rower – tips for rowing college recruiting." />
            </div>
        </div>
        
        <div class="content-wrapper">
        	<cms-template-section id="15d17d52-2ae2-4e15-8cef-d68905483f08"></cms-template-section>
        </div>
        
        
        <cms-post-collection id="0bad3464-478c-4bbb-bf01-899bc13c58af" [component_resolver]="component_resolver"></cms-post-collection>
        
        <div class="cta-wrapper mt-4">
            <cms-template-section id="0fcf2c71-8066-4cd2-af85-8d20cbf05f1c"></cms-template-section>
            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call</a>
            </p>
        </div>
    </div>
</ng-container>