import { CDNService, ProductModel, ProductVariantModel, ProductVariantApiModel, ProductElectiveModel } from '@murdough-solutions/cms-common';
import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';


export function LoadModel(model: Array<CrossSaleModel>, cdn_service: CDNService): Observable<Array<CrossSaleModel>> {
  const requests = new Array<Observable<ProductModel>>();
  const ids = new Array<string>();

  for (const item of model) {
    if (!ids.includes(item.product_variant.product.product_id)) {
      ids.push(item.product_variant.product.product_id);
      requests.push(cdn_service.GetProduct(item.product_variant.product.product_id, false));
    }
  }

  if (requests.length === 0) {
    return of(model);
  }

  return forkJoin(requests).pipe(map(responses => {

    return model.map(item => {
      const product = responses.find(p => p.product_id === item.product_variant.product.product_id);
      if (!product) {
        return item;
      }
      let variant = product.variants.find(f => f.product_variant_id === item.product_variant.product_variant_id);
      if (!variant) {
        variant = product.administrative_variants?.find(f => f.product_variant_id === item.product_variant.product_variant_id);
      }

      return {
        ...item,
        product_variant: {
          ...variant,
          product: {
            ...product,
            variants: [],
            administrative_variants: []
          } as ProductModel
        } as ProductVariantModel,
      } as CrossSaleModel;
    });
  }));
}

export interface CrossSaleModel {
  coupon_product_variant_id: string;
  original_price: number;
  price: number;
  product_variant: ProductVariantApiModel;
  product_price_id: string;
  electives: Array<ProductElectiveModel>;
}
