import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CDNService, ProductCollectionModel } from '@murdough-solutions/cms-common';
import { Observable } from 'rxjs';

@Injectable()
export class ProductCollectionResolver  {

    constructor(private cdn: CDNService)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<ProductCollectionModel> {
        return this.cdn.GetProductCollection(route.data.product_collection_id);
    }
}
