import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CDNService,
  CheckoutService as CoreCheckoutService,
  CountryService,
  CouponModel,
  FormCompletionModel,
  OrderItemModel,
  OrderModel,
  ProductCategoryModel,
  ProductCollectionModel,
  ProductInventoryType,
  ProductModel,
  ProductPriceModel,
  ProductVariantModel,
  WebsiteCountryModel,
} from '@murdough-solutions/cms-common';
import { Observable, forkJoin, shareReplay } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { GetOrderArgument } from 'src/app/interfaces/order-search.interface';
import { CheckoutElectiveRatesModel } from 'src/app/models/checkout-elective-rates.model';
import { CheckoutPaymentModel } from 'src/app/models/checkout-payment.model';
import {
  CrossSaleModel,
  LoadModel as LoadCrossSale,
} from 'src/app/models/cross-sale.model';
import {
  DeterminedElectivePrice,
  DeterminedProductPrice,
  ProductPriceTypes,
} from 'src/app/models/product-price.model';
import {
  QuickCheckoutPayload,
  QuickCheckoutPaymentModel,
} from 'src/app/models/quick-checkout.model';
import { ReceiptModel } from 'src/app/models/receipt.model';
import { ShippingRateModel } from 'src/app/models/shipping-rate.model';
import { ValidateInventoryModel } from 'src/app/models/validate-inventory.model';
import { OrderItemPayload } from 'src/app/payloads/order-item.payload';
import { ApiService } from './api.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { CheckoutElectiveRatesPayload } from 'src/app/payloads/checkout-elective-rates.payload';

@Injectable()
export class CheckoutService {
  constructor(
    private readonly router: Router,
    private readonly api: ApiService,
    private readonly country_service: CountryService,
    private readonly cdn_service: CDNService,
    private readonly gtag: GoogleTagManagerService,
    private readonly core_checkout: CoreCheckoutService
  ) {}

  public formsAvailable(order: OrderModel, features: Array<string>): boolean {
    if (order) {
      if (
        order.forms?.find(
          (f) => f.feature && features?.includes(f.feature) === true
        ) != null
      ) {
        return true;
      }
      for (const item of order.items) {
        if (
          item.forms?.find(
            (f) => f.feature && features?.includes(f.feature) === true
          ) != null
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public electiveAvailable(order: OrderModel, type_mapping: string): boolean {
    if (order) {
      const electives = order.items.flatMap(
        (item) => item.variant.product.electives ?? []
      );
      if (electives.find((elective) => elective.type_mapping == type_mapping)) {
        return true;
      }
    }
    return false;
  }

  public createOrderItem(order_item: OrderItemPayload): void {
    this.SaveItem(order_item).subscribe((order) => {
      this.router.navigate(['/checkout']);
    });
  }

  public SaveItem(model: OrderItemPayload): Observable<OrderModel> {
    return this.api
      .put<OrderModel, OrderItemPayload>(['client', 'checkout', 'items'], model)
      .pipe(
        switchMap((data) => {
          return this.cdn_service.LoadOrderModel(data);
        }),
        tap((order) => {
          this.core_checkout.setOrder(order);
          const item = order.items.find(
            (f) => f.variant.product_variant_id == model.product_variant_id
          );
          if (item) {
            this.GtagProductAddToCart(order, item);
          }
        })
      );
  }

  public GetOrder(argument: GetOrderArgument): Observable<OrderModel> {
    return this.country_service.country$.pipe(
      switchMap(() =>
        this.api.post<OrderModel, GetOrderArgument>(
          ['client', 'checkout'],
          argument
        )
      ),
      switchMap((data) => {
        return this.cdn_service.LoadOrderModel(data);
      }),
      tap((order) => {
        if (argument.step) {
          this.GtagCheckout(argument.step, order);
        }
      })
    );
  }

  public ChangeCountry(argument: WebsiteCountryModel): Observable<unknown> {
    return this.api.put<unknown, WebsiteCountryModel>(
      ['client', 'checkout', 'country'],
      argument
    );
  }

  public GetCrossSales(): Observable<Array<CrossSaleModel>> {
    return this.api
      .get<Array<CrossSaleModel>>(['client', 'checkout', 'cross-sales'])
      .pipe(
        switchMap((data) => {
          return LoadCrossSale(data, this.cdn_service);
        })
      );
  }

  public SaveOrderItemForm(
    order_item_id: string,
    model: FormCompletionModel
  ): Observable<{}> {
    const form = Object.assign({}, model);
    form.order_item = undefined;
    return this.api.put<FormCompletionModel, FormCompletionModel>(
      ['client', 'checkout', 'order-items', order_item_id, 'forms'],
      form
    );
  }

  public SaveOrderForm(model: FormCompletionModel): Observable<OrderModel> {
    return this.api.put<OrderModel, FormCompletionModel>(
      ['client', 'checkout', 'forms'],
      model
    );
  }

  public GetElectiveRates(
    elective_type: string
  ): Observable<Array<CheckoutElectiveRatesModel>> {
    return this.api.get<Array<CheckoutElectiveRatesModel>>(
      ['client', 'checkout', 'elective-rates'],
      {
        params: {
          elective_type,
        },
      }
    );
  }

  public PostElectiveRates(
    payload: CheckoutElectiveRatesPayload
  ): Observable<Array<CheckoutElectiveRatesModel>> {
    return this.api.post<Array<CheckoutElectiveRatesModel>, CheckoutElectiveRatesPayload>(
      ['client', 'checkout', 'elective-rates'],
      payload
    );
  }

  public ValidateInventory(): Observable<Array<ValidateInventoryModel>> {
    return this.api.get<Array<ValidateInventoryModel>>([
      'client',
      'checkout',
      'validate-inventory',
    ]);
  }

  public GetPayment(argument: GetOrderArgument): Observable<OrderModel> {
    return this.api
      .post<OrderModel, GetOrderArgument>(
        ['client', 'checkout', 'payment'],
        argument
      )
      .pipe(
        switchMap((data) => {
          return this.cdn_service.LoadOrderModel(data);
        }),
        tap((order) => {
          if (argument.step) {
            this.GtagCheckout(argument.step, order);
          }
        })
      );
  }

  public GetShippingRates(): Observable<Array<ShippingRateModel>> {
    return this.api.get<Array<ShippingRateModel>>([
      'client',
      'checkout',
      'shipping-rates',
    ]);
  }

  public SetShippingRate(
    shipping_rate: ShippingRateModel
  ): Observable<unknown> {
    return this.api.put<unknown, ShippingRateModel>(
      ['client', 'checkout', 'shipping-rates'],
      shipping_rate
    );
  }

  public SavePayment(model: CheckoutPaymentModel): Observable<ReceiptModel> {
    return this.api
      .put<ReceiptModel, CheckoutPaymentModel>(
        ['client', 'checkout', 'payment'],
        model
      )
      .pipe(
        tap((receipt) => {
          this.GtagPurchase(receipt);
        })
      );
  }

  public DeleteItem(
    order: OrderModel,
    item: OrderItemModel
  ): Observable<unknown> {
    return this.api
      .delete(['client', 'checkout', 'items', item.order_item_id])
      .pipe(
        tap(() => {
          this.GtagProductRemoveFromCart(order, item);
        })
      );
  }

  public SaveCoupon(model: CouponModel): Observable<unknown> {
    return this.api.put<OrderModel, CouponModel>(
      ['client', 'checkout', 'coupons'],
      model
    );
  }

  public DeleteCoupon(coupon_id: string): Observable<unknown> {
    return this.api.delete<unknown>([
      'client',
      'checkout',
      'coupons',
      coupon_id,
    ]);
  }

  public GetConfirmation(
    order_id: string,
    argument: GetOrderArgument
  ): Observable<OrderModel> {
    return this.api
      .post<OrderModel, GetOrderArgument>(
        ['client', 'checkout', 'confirmation', order_id],
        argument
      )
      .pipe(
        switchMap((data) => {
          return this.cdn_service.LoadOrderModel(data);
        })
      );
  }

  public determinePurchasePrice(
    product: ProductModel,
    options?: {
      variant?: ProductVariantModel;
      administrative?: boolean;
      full_price?: boolean;
      elective_type?: string
    }
  ): Observable<DeterminedProductPrice | undefined> {
    const inventory_available =
      product.inventory_type === ProductInventoryType.Unlimited ||
      (options?.variant
        ? options.variant.available_inventory > 0
        : product.available_inventory > 0);

    return this.country_service.country$.pipe(
      map((country) => {
        let prices: ProductPriceModel[];
        let electives = product.electives.filter(f=>f.type_mapping == options?.elective_type) ?? [];

        if (options?.administrative) {
          prices = product.administrative_prices;
        } else {
          prices = product.prices;
        }

        if (options?.variant) {
          const variant = options.variant;
          prices = prices.filter((f) =>
            f.variant_ids.includes(variant.product_variant_id)
          );
          electives = electives.filter((f) =>
            f.variant_ids.includes(variant.product_variant_id)
          );
        }

        const electives_determined: DeterminedElectivePrice[] = electives.map(
          (elective) => {
            const elective_country = elective.countries.find(
              (f) => f.country.country_id == country?.country_id
            );
            return {
              product_elective_id: elective.product_elective_id,
              name: elective.name,
              type_mapping: elective.type_mapping,
              price: elective_country?.price ?? 0,
            };
          }
        );

        if (inventory_available) {
          if (!options?.administrative) {
            const deposit_price = prices.find(
              (f) => f.product_price_type.name == ProductPriceTypes.Deposit
            );
            if (deposit_price) {
              let countries = deposit_price.countries;
              if (options?.full_price && deposit_price.parent) {
                countries = deposit_price.parent.countries;
              }
              const country_price = countries.find(
                (f) => f.country.country_id == country?.country_id
              );
              if (country_price) {
                return new DeterminedProductPrice(
                  deposit_price,
                  country_price,
                  electives_determined,
                  true
                );
              }
            }
          }

          const normal_price = prices.find(
            (f) =>
              f.product_price_type.name ==
              (options?.administrative
                ? ProductPriceTypes.Administrative
                : ProductPriceTypes.Normal)
          );
          if (normal_price) {
            const country_price = normal_price.countries.find(
              (f) => f.country.country_id == country?.country_id
            );
            if (country_price) {
              return new DeterminedProductPrice(
                normal_price,
                country_price,
                electives_determined,
                inventory_available
              );
            }
          }
        }

        const waitlist_price = prices.find(
          (f) =>
            f.product_price_type.name == ProductPriceTypes.Waitlist ||
            f.product_price_type.name == ProductPriceTypes.Reservation
        );
        if (waitlist_price) {
          let countries = waitlist_price.countries;
          if (options?.full_price && waitlist_price.parent) {
            countries = waitlist_price.parent.countries;
          }
          const country_price = countries.find(
            (f) => f.country.country_id == country?.country_id
          );
          if (country_price) {
            const determined = new DeterminedProductPrice(
              waitlist_price,
              country_price,
              electives_determined,
              true
            );
            if (!waitlist_price.parent) {
              determined.reservation_state = true;
            }
            return determined;
          }
        }

        return undefined;
      }),
      shareReplay(1)
    );
  }

  public QuickCheckout(model: QuickCheckoutPayload): Observable<OrderModel> {
    return this.api.put<OrderModel, QuickCheckoutPayload>(
      ['client', 'quick-checkout'],
      model
    );
  }

  public QuickCheckoutPayment(
    order_id: string,
    model: QuickCheckoutPaymentModel
  ): Observable<ReceiptModel> {
    return this.api.put<ReceiptModel, QuickCheckoutPaymentModel>(
      ['client', 'quick-checkout', order_id, 'payment'],
      model
    );
  }

  public GtagProductCollectionView(
    product_collection: ProductCollectionModel
  ): void {
    forkJoin(
      product_collection.products.map((product) =>
        forkJoin(
          product.variants.map((variant) =>
            this.determinePurchasePrice(product, { variant }).pipe(
              map((price) => ({
                ...variant,
                price,
              })),
              take(1)
            )
          )
        ).pipe(
          map((variants) => ({
            ...product,
            variants,
          }))
        )
      )
    )
      .pipe(take(1))
      .subscribe((products) => {
        const items = [];
        let i = 0;
        for (const product of products) {
          for (const variant of product.variants) {
            items.push({
              currency: variant.price?.country.currency_code,
              item_name: product.name, // Name or ID is required.
              item_id: product.product_id,
              price: variant.price?.price,
              // 'brand': 'Google',
              // 'category': 'Apparel',
              item_variant: variant.name,
              item_list_id: product_collection.product_collection_id,
              item_list_name: product_collection.name,
              index: i,
            });
          }
          i++;
        }

        this.gtag.pushLayer({
          event: 'view_item_list',
          ecommerce: {
            items,
          },
        });
      });
  }

  public GtagProductCategoryView(product_category: ProductCategoryModel): void {
    forkJoin(
      product_category.products.map((product) =>
        forkJoin(
          product.variants.map((variant) =>
            this.determinePurchasePrice(product, { variant }).pipe(
              map((price) => ({
                ...variant,
                price,
              })),
              take(1)
            )
          )
        ).pipe(
          map((variants) => ({
            ...product,
            variants,
          }))
        )
      )
    )
      .pipe(take(1))
      .subscribe((products) => {
        const items = [];
        let i = 0;
        for (const product of products) {
          for (const variant of product.variants) {
            items.push({
              currency: variant.price?.country.currency_code,
              item_name: product.name, // Name or ID is required.
              item_id: product.product_id,
              price: variant.price?.price,
              // 'brand': 'Google',
              // 'category': 'Apparel',
              item_variant: variant.name,
              item_list_id: product_category.product_category_id,
              item_list_name: product_category.name,
              index: i,
            });
          }
          i++;
        }

        this.gtag.pushLayer({
          event: 'view_item_list',
          ecommerce: {
            items,
          },
        });
      });
  }

  public GtagProductView(product: ProductModel): void {
    forkJoin(
      product.variants.map((variant) =>
        this.determinePurchasePrice(product, { variant }).pipe(
          map((price) => ({
            ...variant,
            price,
          })),
          take(1)
        )
      )
    )
      .pipe(take(1))
      .subscribe((variants) => {
        const items = [];
        const i = 0;
        for (const variant of variants) {
          items.push({
            currency: variant.price?.country?.currency_code,
            item_name: product.name, // Name or ID is required.
            item_id: product.product_id,
            price: variant.price?.price,
            // 'brand': 'Google',
            // 'category': 'Apparel',
            item_variant: variant.name,
            index: i,
          });
        }

        this.gtag.pushLayer({
          event: 'view_item',
          ecommerce: {
            items,
          },
        });
      });
  }

  public GtagProductAddToCart(order: OrderModel, item: OrderItemModel): void {
    const items = [
      {
        currency: order.country.currency_code,
        item_name: item.variant.product?.name, // Name or ID is required.
        item_id: item.variant.product.product_id,
        price: item.price,
        // 'brand': 'Google',
        // 'category': 'Apparel',
        item_variant: item.variant.name,
        quantity: item.quantity,
        index: 0,
      },
    ];

    this.gtag.pushLayer({
      event: 'add_to_cart',
      ecommerce: {
        items,
      },
    });
  }

  public GtagProductRemoveFromCart(
    order: OrderModel,
    item: OrderItemModel
  ): void {
    const items = [
      {
        currency: order.country.currency_code,
        item_name: item.variant.product?.name, // Name or ID is required.
        item_id: item.variant.product.product_id,
        price: item.price,
        // 'brand': 'Google',
        // 'category': 'Apparel',
        item_variant: item.variant.name,
        quantity: item.quantity,
        index: 0,
      },
    ];

    this.gtag.pushLayer({
      event: 'remove_from_cart',
      ecommerce: {
        items,
      },
    });
  }

  public GtagCheckout(step: number, order: OrderModel): void {
    if (!order.country) {
      return;
    }
    const items = [];
    let i = 0;
    for (const order_item of order.items) {
      items.push({
        currency: order.country.currency_code,
        item_name: order_item.variant.product?.name, // Name or ID is required.
        item_id: order_item.variant.product.product_id,
        price: order_item.price,
        // 'brand': 'Google',
        // 'category': 'Apparel',
        item_variant: order_item.variant.name,
        quantity: order_item.quantity,
        index: i,
      });
      i++;
    }

    this.gtag.pushLayer({
      event: 'view_cart',
      ecommerce: {
        currency: order.country.currency_code,
        value: order.total,
        items,
      },
    });
  }

  public GtagPurchase(receipt: ReceiptModel): void {
    const order = receipt.order;
    const items = [];
    let i = 0;
    for (const order_item of order.items) {
      items.push({
        currency: order.country.currency_code,
        item_name: order_item.variant.product?.name, // Name or ID is required.
        item_id: order_item.variant.product.product_id,
        price: order_item.price,
        // 'brand': 'Google',
        // 'category': 'Apparel',
        item_variant: order_item.variant.name,
        quantity: order_item.quantity,
        index: i,
      });
      i++;
    }

    this.gtag.pushLayer({
      event: 'purchase',
      ecommerce: {
        transaction_id: order.order_id,
        currency: order.country?.currency_code,
        value: order.total,
        shipping: order.shipping,
        tax: order.tax,
        items,
      },
    });
  }
}
