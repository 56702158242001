import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IdentityService, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdough-solutions/cms-common';

@Injectable()
export class PageGuard  {
    constructor(@Inject(WEBSITE_DOMAIN) private readonly domain: WebsiteDomainModel, private router: Router, private identity_service: IdentityService) {

    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return from(this.identity_service.CheckRoles(next.data.page_model?.roles))
            .pipe(
                map((authorized: boolean) => {
                    if (this.domain.website.login_page && !authorized) {
                        sessionStorage.setItem('redirect_route', '/' + next.url.join('/'));
                        this.router.navigate(this.domain.website.login_page.route);
                        return false;
                    }
                    return true;
                }),
                catchError((err) => {
                    console.log(err);
                    return of(true);
                })
            );

    }
}
