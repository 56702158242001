import { Component, Inject } from '@angular/core';
import { CDNService, ContentModel, ProductCategoryModel, ProductCollectionModel, ProductModel, WidgetModel } from '@murdough-solutions/cms-common';
import { Observable } from 'rxjs';
import { CONTENT_MODEL, WIDGET_MODEL } from '@murdough-solutions/cms-client';

@Component({
  selector: 'CustomCampsCarousel',
  templateUrl: './camps-carousel.html',
  styleUrls: ['./camps-carousel.scss']
})
export class CustomCampsCarousel {

  public current_product_category?: ProductCategoryModel;
  public readonly products_map: { [name: string]: Array<ProductModel> } = {};
  public readonly product_collection$: Observable<ProductCollectionModel> | undefined;

  constructor(
    @Inject(WIDGET_MODEL) public readonly widget: WidgetModel,
    @Inject(CONTENT_MODEL) public readonly content: ContentModel,
    public readonly cdn: CDNService
  ) {
    const product_collection_id = this.content.properties?.product_collection?.product_collection?.product_collection_id;
    if (product_collection_id) {
      this.product_collection$ = this.cdn.GetProductCollection(product_collection_id);
    }
  }

  public loadProductCategory(category: ProductCategoryModel | undefined): void {
    this.current_product_category = category;
    if (category && !this.products_map[category.product_category_id]) {
      this.cdn.GetProductCategory(category.product_category_id).subscribe((data) => {
        this.products_map[category.product_category_id] = data.products;
      });
    }
  }

}
