import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PageGuard } from './guards/page.guard';
import { ContentResolver } from './resolvers/content.resolver';
import { PostCategoryResolver } from './resolvers/post-category.resolver';
import { PostCollectionResolver } from './resolvers/post-collection.resolver';
import { PostResolver } from './resolvers/post.resolver';
import { ProductCategoryResolver } from './resolvers/product-category.resolver';
import { ProductCollectionResolver } from './resolvers/product-collection.resolver';
import { ProductResolver } from './resolvers/product.resolver';
import { CheckoutService } from './services/checkout.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { LoginService } from './services/login.service';
import { MetaService } from './services/meta.service';


import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CmsClientModule } from '@murdough-solutions/cms-client';
import { CmsCommonModule } from '@murdough-solutions/cms-common';
import { NgPipesModule } from 'ngx-pipes';
import { EventService } from './services/event.service';
import { RouteBuilderService } from './services/route-builder.service';
import { ApiService } from './services/api.service';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        MatSnackBarModule,
        NgPipesModule,
        CmsCommonModule,
        CmsClientModule
    ],
    exports: [
        NgbModule,
        NgPipesModule,
        CmsCommonModule,
        CmsClientModule
    ]
})
export class CoreModule {

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ApiService,
                GoogleTagManagerService,
                MetaService,
                LoginService,
                CheckoutService,
                EventService,
                PageGuard,
                ContentResolver,
                PostCollectionResolver,
                PostCategoryResolver,
                PostResolver,
                ProductCollectionResolver,
                ProductResolver,
                ProductCategoryResolver,
                RouteBuilderService,
                {
                    provide: NgbDateAdapter,
                    useClass: NgbDateNativeAdapter
                },
                {
                    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
                    useValue: {
                        appearance: 'outline',
                        subscriptSizing: 'dynamic'
                    }
                },
            ]
        };
    }
}
