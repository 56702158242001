<ng-container *ngIf="content$ | async as content">
    <div class="container page-banner dark-banner" *ngIf="content.properties['banner_image']?.asset as banner_image" [style.background-image]="'url('+ cdn.getAssetUrl(banner_image) +')'">
        <div class="banner-content">
            <cms-template-section id="98f4d6af-f40e-4a6a-8611-be73febf08a9"></cms-template-section>
        </div>
    </div>
    
    <div class="container bg-white pt-5 pb-5">
        <div class="content-wrapper mb-5">
        	<cms-template-section id="b6c354d7-354a-432b-8826-20de34111f35"></cms-template-section>
           	<p class="mt-3 text-center">
                <a *ngIf="content.properties['counseling_page']?.page as counseling_page" [routerLink]="counseling_page.route" class="btn btn-primary">Learn More</a>
            </p>
        </div>
        <div class="content-wrapper mb-5">
            <cms-template-section id="2d0f6f0c-d13f-494e-b9f0-b319b0e802fe"></cms-template-section>
           	<p class="mt-3 text-center">
                <a *ngIf="content.properties['camps_page']?.page as camps_page" [routerLink]="camps_page.route" class="btn btn-primary">Browse Sparks Camps</a>
            </p>
        </div>
        
        <div class="content-wrapper">
            	<cms-template-section id="b075652e-1b56-476e-a6ef-6b26409b81a7"></cms-template-section>
        </div>
        <div class="mb-5">
            <cms-post-collection id="b1a0f398-e06d-4977-b92c-06a8e0e5bc02" [component_resolver]="component_resolver"></cms-post-collection>
        </div>
        <div class="content-wrapper">
            <cms-template-section id="4f7ab68f-bcf2-4da4-a05c-7123d572ccec"></cms-template-section>
           	<p class="mt-3 text-center">
                <a *ngIf="content.properties['jobs_page']?.page as jobs_page" [routerLink]="jobs_page.route" class="btn btn-primary">Work at Sparks</a>
            </p>
        </div>
    </div>
</ng-container>