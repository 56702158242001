<ng-container *ngIf="page$ | async as page">
    <div class="container bg-white" *ngIf="content$ | async as content">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li *ngFor="let p of cdn.GetPageHeirarchy(page)" class="breadcrumb-item"><a [routerLink]="p.route">{{ p.name }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ page.name }}</li>
            </ol>
        </nav>
        
        <cms-template-section id="bb8890c1-18c3-4902-978f-a2f7ecd56968"></cms-template-section>
        
        <iframe *ngIf="content.properties['iframe_url']?.value_character as iframe_url" [src]="iframe_url | safeUrl" width="100%" height="800" frameBorder="0"></iframe>
        <script src="https://d2pfoxivue2zy1.cloudfront.net/cdn/assets/e5c692e4-4a05-442a-85e5-353599eae343.js" type="text/javascript"></script>
        
        <p *ngIf="cdn.GetParentPage(page) as parent" class="mt-4">
            <a [routerLink]="parent.route">&lt; Return to {{ parent.name }}</a>
        </p>
    </div>
</ng-container>