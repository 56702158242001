import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CDNService, ContentModel, PageModel } from '@murdough-solutions/cms-common';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MetaService } from 'src/app/modules/core/services/meta.service';
import { component_resolver } from '../../component-map';

@Component({
    selector: 'CustomHomePage',
    templateUrl: './home-page.html',
    styleUrls: ['./home-page.scss']
})
export class CustomHomePage implements OnInit {

    public readonly component_resolver = component_resolver    
    
    constructor(
        private readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly cdn: CDNService,
        private readonly meta_service: MetaService,
    ) {

    }

    public readonly page$ = this.route.data.pipe(
        map(({ page_model }) => page_model as PageModel)
    );

    public readonly content$ = this.route.data.pipe(
        map(({ content_model }) => content_model as ContentModel)
    );

    ngOnInit() {
        combineLatest([this.page$, this.content$]).pipe(
            take(1)
        ).subscribe(([page, content]) => {
            const banner_image = content.properties?.banner_image?.asset;

            if (banner_image) {
                this.meta_service.updateMetaTags([
                    { property: 'og:image', content: this.cdn.getAssetSmallUrl(banner_image) }
                ]);
            }
        });
    }

}
