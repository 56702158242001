import {
  CountryModel,
  ProductPriceCountryModel,
  ProductPriceModel,
} from '@murdough-solutions/cms-common';

export enum ProductPriceTypes {
  Normal = 'Normal',
  Waitlist = 'Waitlist',
  Deposit = 'Deposit',
  Reservation = 'Reservation',
  Administrative = 'Administrative',
}

export interface DeterminedElectivePrice {
  product_elective_id: string;
  name: string;
  type_mapping?: string;
  price: number;
  selected?: boolean;
}

export class DeterminedProductPrice {
  product_price_id: string;
  country: CountryModel;
  type_name: string;
  price: number;
  regular_price?: number;
  purchase_enabled: boolean;
  electives: DeterminedElectivePrice[];
  reservation_state?: boolean;

  constructor(
    price: ProductPriceModel,
    country_price: ProductPriceCountryModel,
    electives: DeterminedElectivePrice[],
    purchase_enabled: boolean
  ) {
    this.product_price_id = price.product_price_id;
    this.type_name = price.product_price_type.name;
    this.country = country_price.country;
    this.price = country_price.discount_price
      ? country_price.discount_price
      : country_price.price;
    this.regular_price = country_price.discount_price
      ? country_price.price
      : undefined;
    this.electives = electives;
    this.purchase_enabled = purchase_enabled;
  }
}
