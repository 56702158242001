import { Component, Inject } from '@angular/core';
import { IdentityService, MenuModel, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdough-solutions/cms-common';
import { LoginService } from 'src/app/modules/core/services/login.service';
import { MENU_MODEL } from '@murdough-solutions/cms-client';

@Component({
  selector: 'CustomMainMenu',
  templateUrl: './main-menu.html',
  styleUrls: ['./main-menu.scss']
})
export class CustomMainMenu {

  constructor(
    @Inject(MENU_MODEL) public readonly menu: MenuModel,
    @Inject(WEBSITE_DOMAIN) public readonly domain: WebsiteDomainModel,
    public readonly login: LoginService,
    public readonly identity: IdentityService
  ) {

  }

}
