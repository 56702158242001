<ng-container *ngIf="content$ | async as content">
    <div class="container page-banner dark-banner" *ngIf="content.properties['banner_image']?.asset as banner_image" [style.background-image]="'url('+ cdn.getAssetUrl(banner_image) +')'">
        <div class="banner-content">
            <cms-template-section id="0cf529c6-3dab-4e13-a540-83dfd53549ed"></cms-template-section>
            <p class="mt-3">
                <a *ngIf="content.properties['database_page']?.page as database_page" [routerLink]="database_page.route" class="btn btn-primary">Get Free Access to our Database</a>
            </p>
        </div>
    </div>
    <div class="container bg-white pt-5 pb-4">
        <div class="content-wrapper">
            <cms-template-section id="5f3260e8-8036-4cc6-9aa8-e05c529dceff"></cms-template-section>
        </div>
    </div>
    <div class="container page-banner" *ngIf="content.properties['middle_banner_image']?.asset as middle_banner_image" [style.background-image]="'url('+ cdn.getAssetUrl(middle_banner_image) +')'">
        <p class="text-center">
            <a *ngIf="content.properties['database_page']?.page as database_page" [routerLink]="database_page.route" class="btn btn-primary">Get Free Access to our College Rowing Database</a>
        </p>
    </div>
    <div class="container bg-white pt-5 pb-4">
        <div class="content-wrapper">
            <cms-template-section id="1e042f44-1bbc-4708-bf1d-ed50b660d85f"></cms-template-section>
        </div>
    </div>
    <div class="container bg-white pt-4 pb-4">
        <div class="content-wrapper mb-4">
            <div class="row">
                <div class="col-md-5">
                    <cms-template-section id="f3ad55fc-5157-423b-9994-11d2b96d23b1"></cms-template-section>
                </div>
                <div class="col-md-7">
                    <cms-template-section id="e167b568-9b02-4c59-acf3-81571a423295"></cms-template-section>
                    <p class="mt-3">
                        <a *ngIf="content.properties['database_page']?.page as database_page" [routerLink]="database_page.route" class="btn btn-primary">Get Started Now</a>
                    </p>
                </div>
            </div>
        </div>
        
        <div class="content-wrapper">
            <cms-template-section id="e2983e0a-0b3b-4e02-96e7-5e66f69db4ba"></cms-template-section>
            <p class="mt-3 text-center">
                <a *ngIf="content.properties['database_page']?.page as database_page" [routerLink]="database_page.route" class="btn btn-primary">Sign up Today</a>
            </p>
        </div>
    </div>
</ng-container>