import { Type } from '@angular/core';

import { CustomBasicPage } from './custom-components/basic-page/basic-page';
import { CustomCoxswainCoaching } from './custom-components/coxswain-coaching/coxswain-coaching';
import { CustomHomePage } from './custom-components/home-page/home-page';
import { CustomPerformanceEnhancementPage } from './custom-components/performance-enhancement-page/performance-enhancement-page';

import { CustomCollegeDatabasePage } from './custom-components/college-database-page/college-database-page';
import { CustomCounselingPage } from './custom-components/counseling-page/counseling-page';
import { CustomGapYearPage } from './custom-components/gap-year-page/gap-year-page';
import { CustomSchedulingPage } from './custom-components/scheduling-page/scheduling-page';
import { CustomSparksStoryPage } from './custom-components/sparks-story-page/sparks-story-page';

import { CustomCampsCarousel } from './custom-components/camps-carousel/camps-carousel';
import { CustomMainMenu } from './custom-components/main-menu/main-menu';
import { CustomFooterMenu } from './custom-components/footer-menu/footer-menu';
import { CustomTestimonialsCarousel } from './custom-components/testimonials-carousel/testimonials-carousel';

export function component_resolver(template_id: string): Type<any> | undefined {
    switch (template_id) {
        case '7e45e80c-c3fe-4f10-9dd2-0bf3325fa18a': return CustomFooterMenu
        case 'bd4ea6a5-055c-49e3-8ba6-1c31fcc68eba': return CustomMainMenu
        case '53b5bc83-a2b9-4510-af7c-f534d314ab90': return CustomTestimonialsCarousel
        case '697f16f8-ba7b-4a94-aa6c-e6e9abe40f5b': return CustomBasicPage
        case '6fdc1b6d-02ff-4fe5-916e-7930d1df7085': return CustomPerformanceEnhancementPage
        case 'e3c52dc6-1186-4509-af3a-a76493007257': return CustomCoxswainCoaching
        case '9bb2246d-2309-4aea-a8bd-32954046fd0d': return CustomSchedulingPage
        case 'a588d7c2-352d-4a62-b34e-8538ae9e8f4c': return CustomGapYearPage
        case '7fc055b6-8cbb-421f-b173-bfa49b26a75a': return CustomCounselingPage
        case 'd2ffa58b-b9e0-463e-af16-c3595cb1ce2c': return CustomCollegeDatabasePage
        case '42dc9cc5-b2a5-4f34-8be6-8e4c8b479923': return CustomSparksStoryPage
        //home
        case '10894137-115d-4ac6-9ba4-8db9690a1a93': return CustomHomePage
        case '6556d225-42ac-49cd-93a5-8caf5b938660': return CustomCampsCarousel
    }
    return undefined
}