import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService, IdentityService, MenuItemModel, TokenModel, UserModel, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdough-solutions/cms-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ForgotPasswordModel } from 'src/app/models/forgot-password.model';
import { LoginModel } from 'src/app/models/login.model';
import { ResetPasswordModel } from 'src/app/models/reset-password.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService {

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly identity: IdentityService,
        @Inject(WEBSITE_DOMAIN) private readonly domain: WebsiteDomainModel,
        private readonly checkout: CheckoutService
    ) {

    }

    public Login(argument: LoginModel): Observable<TokenModel> {
        return this.http.post<TokenModel>(environment.service_base + 'api/v1/identity/login', argument).pipe(
            take(1),
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err.error?.message ?? err.message);
            }));

    }

    public Register(argument: UserModel): Observable<TokenModel> {
        return this.http.put<TokenModel>(environment.service_base + 'api/v1/identity/register', argument).pipe(
            take(1),
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err.error?.message ?? err.message);
            }));

    }

    public ForgotPassword(argument: ForgotPasswordModel): Observable<{}> {
        return this.http.post(environment.service_base + 'api/v1/identity/forgot-password', argument).pipe(
            take(1),
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err.error?.message ?? err.message);
            }));

    }

    public ResetPassword(argument: ResetPasswordModel): Observable<{}> {
        return this.http.put(environment.service_base + 'api/v1/identity/reset-password', argument).pipe(
            take(1),
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err.error?.message ?? err.message);
            }));
    }

    public GetMe(): Observable<UserModel> {
        return this.http.get<UserModel>(environment.service_base + 'api/v1/identity/me').pipe(
            take(1),
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err.error?.message ?? err.message);
            }));

    }


    public Logout(): void {
        this.identity.ClearToken();
        this.checkout.clearOrder();
        if (this.domain.website.login_page) {
            this.router.navigate(this.domain.website.login_page.route);
        }
    }

    public MenuItemAuthorized(item: MenuItemModel): Observable<boolean> {
        if (item.inverse_roles) {
            return this.identity.CheckRoles(item.roles ?? []).pipe(map(authorized => {
                return !authorized;
            }));
        }
        else {
            return this.identity.CheckRoles(item.roles ?? []);
        }
    }

}
