import { Injectable } from '@angular/core';
import { FormCompletionModel } from '@murdough-solutions/cms-common';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { environment } from 'src/environments/environment';

@Injectable()
export class GoogleTagManagerService {

    constructor(
        private readonly gtm: Angulartics2GoogleTagManager
    ) {
    }

    public pushLayer(layer: any): void {
        if (!environment.production) {
            return
        }
        this.gtm.pushLayer({ ecommerce: null, form: null });
        this.gtm.pushLayer(layer);
    }



    public FormCompletion(completion: FormCompletionModel): void {
        if (!completion.conversion_event_name) {
            return;
        }

        this.pushLayer({
            event: completion.conversion_event_name,
            form: {
                name: completion.name
            }
        });
    }

}
