<ng-container *ngIf="content$ | async as content">
    <div class="container page-banner dark-banner">
        <ng-container *ngIf="content.properties['banner_image']?.asset as banner_image">
            <img class="banner-image d-block d-lg-none" [src]="cdn.getAssetMediumUrl(banner_image)" alt="Sparks coxswain training and coaching – develop 4 key areas to be a better coxswain." />
            <img class="banner-image d-none d-lg-block" [src]="cdn.getAssetUrl(banner_image)" alt="Sparks coxswain training and coaching – develop 4 key areas to be a better coxswain." />
        </ng-container>
        <div class="row">
            <div class="col-md-7">
                <div class="banner-content">
                    <cms-template-section id="97f85074-38e1-4684-8afc-4a8a56ff65bb"></cms-template-section>
                    <p *ngIf="content.properties['schedule_page']?.page as schedule_page" class="mt-3">
                        <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container bg-white pt-5 pb-5">
        <div class="content-wrapper mt-3">
            <cms-template-section id="0b64932b-b84a-40f8-bb2d-49c66cf5b50d"></cms-template-section>
            
            <div class="ratio ratio-16x9 mb-3">
                <iframe src="//player.vimeo.com/video/120101014?title=0&amp;amp;byline=0" frameborder="0" allowfullscreen="allowfullscreen" data-mce-fragment="1"></iframe>
            </div>
            
            <cms-template-section id="90748565-663f-45f7-8168-91ac2783ab9f"></cms-template-section>
            
        </div>
        
        <div class="cta-wrapper mt-5 mb-5">
            <cms-template-section id="031c400b-1677-4f7b-8c27-2dce92c5e057"></cms-template-section>
            
            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call</a>
            </p>
        </div>
        
        <cms-template-section id="e4db850b-3496-48b7-bf2c-a04e2c8922a7"></cms-template-section>
        <cms-post-collection id="c0b0277d-8d95-417a-bf97-c23224e010ca" [component_resolver]="component_resolver"></cms-post-collection>
        
        <div class="cta-wrapper mt-4">
            <cms-template-section id="7f74f7de-f471-4a51-8188-0434d28879b1"></cms-template-section>
            
            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call</a>
            </p>
        </div>
    </div>
</ng-container>