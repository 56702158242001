<ul class="navbar-nav">
    <ng-container *ngFor="let item of menu.items | orderBy: 'position'">
        <ng-container *ngIf="login.MenuItemAuthorized(item) | async">
            <li class="nav-item" *ngIf="item.children?.length == 0">
                <a *ngIf="item.route" class="nav-link" [routerLink]="item.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ identity.tokenizeString(item.name) | async }}</a>
            </li>
            <li class="nav-item dropdown" ngbDropdown *ngIf="item.children && item.children.length > 0">
                <a class="nav-link dropdown-toggle button-link cursor-pointer" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>{{ identity.tokenizeString(item.name) | async }}</a>
                <div class="dropdown-menu" ngbDropdownMenu>
                    <ng-container *ngFor="let child of item.children | orderBy: 'position'">
                        <a class="dropdown-item" *ngIf="login.MenuItemAuthorized(child) | async" [routerLink]="child.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" ngbDropdownItem>{{ identity.tokenizeString(child.name) | async }}</a>
                    </ng-container>
                </div>
            </li>
        </ng-container>
    </ng-container>

    <li class="nav-item">
        <ng-template #notAuthed>
            <a *ngIf="domain.website?.login_page as login_page" class="nav-link" [routerLink]="login_page.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ login_page.name }}</a>
        </ng-template>
        
        <a *ngIf="identity.isAuthenticated() | async; else notAuthed" class="nav-link cursor-pointer" (click)="login.Logout()">Logout</a>
    </li>
</ul>