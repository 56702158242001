<ng-container *ngIf="content$ | async as content">
    <div id="home-banner" class="page-banner">
        <ng-container *ngIf="content.properties['banner_image']?.asset as banner_image">
            <img class="banner-image d-block d-lg-none" [src]="cdn.getAssetMediumUrl(banner_image)" />
            <img class="banner-image d-none d-lg-block" [src]="cdn.getAssetUrl(banner_image)" />
        </ng-container>
        <div class="container">
            <div class="banner-content">
                <cms-template-section id="585c6b7f-2369-4e71-a9ca-624404072af2"></cms-template-section>
                <p class="mt-3">
                    <a *ngIf="content.properties['college_counseling_page']?.page as college_counseling_page" [routerLink]="college_counseling_page.route" class="btn btn-primary">{{ college_counseling_page.name }}</a>
                    <a *ngIf="content.properties['top_banner_second_page']?.page as top_banner_second_page" [routerLink]="top_banner_second_page.route" class="btn btn-secondary ms-3">{{ top_banner_second_page.name }}</a>
                </p>
            </div>
        </div>
    </div>
    <div class="bg-white pt-5 pb-4">
        <div class="container">
            <div class="content-wrapper">
               	<cms-template-section id="4cd4c892-49f2-424b-85b1-1760227800f2"></cms-template-section>
               	<p class="mt-3 text-center">
                    <a *ngIf="content.properties['story_page']?.page as story_page" [routerLink]="story_page.route" class="btn btn-secondary">Learn More</a>
                </p>
            </div>
        </div>
    </div>
    <div id="story-banner" class="page-banner">
        <ng-container *ngIf="content.properties['story_banner_image']?.asset as story_banner_image">
            <img class="banner-image d-block d-lg-none" [src]="cdn.getAssetMediumUrl(story_banner_image)" />
            <img class="banner-image d-none d-lg-block" [src]="cdn.getAssetUrl(story_banner_image)" />
        </ng-container>
        <div class="container">
            <div class="story-content pt-5 pb-4">
                <cms-template-section id="147f9c80-2b43-4210-a76d-87904fa30b19"></cms-template-section>
                <p class="mt-3">
                    <a *ngIf="content.properties['story_page']?.page as story_page" [routerLink]="story_page.route" class="btn btn-primary">Learn More</a>
                </p>
            </div>
        </div>
    </div>
    <div class="bg-white pt-5 pb-4">
        <div class="container">
            <div class="content-wrapper">
                <cms-template-section id="745226ad-741a-4c7e-83ee-d92bc8b38788"></cms-template-section>
                <div class="row mt-3">
                    <div class="col-md-4">
                        <cms-template-section id="963c0358-4c04-4493-b116-6aae8b19706b"></cms-template-section>
                    </div>
                    <div class="col-md-4">
                        <cms-template-section id="da062c56-0c0b-44d8-a3ef-ad0d3a334185"></cms-template-section>
                    </div>
                    <div class="col-md-4">
                    	<cms-template-section id="1b79d8b8-74b4-4b2f-b0d2-cfa519b46313"></cms-template-section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="bg-light pt-5 pb-4">
        <div class="container">
            <cms-template-section id="2cbb8c5d-d712-438e-bd88-7a99284dc731"></cms-template-section>
            <cms-widget id="54e88b52-ca5f-48b6-9046-dd8d65b22205" [component_resolver]="component_resolver"></cms-widget>
        </div>
    </div>
    
    <div class="bg-white pt-5 pb-4">
        <div class="container">
            <div class="content-wrapper">
                <cms-template-section id="bb97043f-c919-40dd-9f1b-47bcd7efc7dc"></cms-template-section>
                <div class="row mt-4">
                    <div class="col-md-5">
                        <cms-template-section id="25fcf3e9-443a-40d2-bb19-5eba01a1d233"></cms-template-section>
                        <a *ngIf="content.properties['elearning_page']?.page as elearning_page" [routerLink]="elearning_page.route" class="btn btn-primary">Explore eLearning</a>
                    </div>
                    <div class="col-md-7">
                        <cms-template-section id="63056849-a149-4d70-ba81-13b7a639e2b5"></cms-template-section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="bg-pink pt-5 pb-5">
        <div class="container">
            <div class="content-wrapper mb-4">
                <cms-template-section id="505752fb-9ae6-4bcf-8069-33fc80c74e2e"></cms-template-section>
            </div>
        </div>
        <cms-post-collection id="0bad3464-478c-4bbb-bf01-899bc13c58af" [component_resolver]="component_resolver"></cms-post-collection>
        <div class="container">
            <div class="content-wrapper mt-3">
                <cms-template-section id="2a25c8ef-1900-4dcd-becb-6d962a4998f3"></cms-template-section>
                
                <p class="text-center mt-4">
                    <a *ngIf="content.properties['college_counseling_page']?.page as college_counseling_page" [routerLink]="college_counseling_page.route" class="btn btn-primary">{{ college_counseling_page.name }}</a>
                    <a *ngIf="content.properties['coxswains_page']?.page as coxswains_page" [routerLink]="coxswains_page.route" class="btn btn-secondary ml-3">{{ coxswains_page.name }}</a>
                </p>
            </div>
        </div>
    </div>
    
    <div class="bg-white pt-5 pb-5">
        <div class="container">
            <div class="content-wrapper">
                <cms-template-section id="caf83d03-14cd-42fb-b7d3-6a041cebef1e"></cms-template-section>
                <div class="row mt-4">
                    <div class="col-md-4">
                        <cms-template-section id="f66595d6-d2f0-466f-a201-4efc4a633d31"></cms-template-section>
                        <p class="mt-3 text-center">
                            <a *ngIf="content.properties['about_page']?.page as about_page" [routerLink]="about_page.route" class="btn btn-secondary">Meet Our Team</a>
                        </p>
                    </div>
                    <div class="col-md-4">
                        <cms-template-section id="99227ed3-0853-4dc4-a238-252a5aaaea30"></cms-template-section>
                        <p class="mt-3 text-center">
                            <a *ngIf="content.properties['story_page']?.page as story_page" [routerLink]="story_page.route" class="btn btn-secondary">Learn More</a>
                        </p>
                    </div>
                    <div class="col-md-4">
                    	<cms-template-section id="932c0876-022e-4db2-9b7b-cc06607e90b7"></cms-template-section>
                    	<p class="mt-3 text-center">
                            <a *ngIf="content.properties['story_page']?.page as story_page" [routerLink]="story_page.route" class="btn btn-secondary">Learn More</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
