<ul class="nav justify-content-center justify-content-sm-end mb-2 mb-sm-0">
    <ng-container *ngFor="let item of menu.items | orderBy: 'position'">
        <li class="nav-item" *ngIf="login.MenuItemAuthorized(item) | async">
            <a *ngIf="item.route" class="nav-link" [routerLink]="item.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ identity.tokenizeString(item.name) | async }}</a>
        </li>
    </ng-container>
    <li class="nav-item session-link">
        <ng-template #notAuthed>
            <a *ngIf="domain.website?.login_page as login_page" class="nav-link" [routerLink]="login_page.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ login_page.name }}</a>
        </ng-template>
        
        <a *ngIf="identity.isAuthenticated() | async; else notAuthed" class="nav-link cursor-pointer" (click)="login.Logout()">Logout</a>
    </li>
</ul>