import { Inject, Injectable, Type } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { PageModel, PostCategoryModel, PostCollectionModel, PostModel, ProductCategoryModel, ProductCollectionModel, ProductModel, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdough-solutions/cms-common';
import { component_resolver } from 'src/app/component-map';
import { PageGuard } from '../guards/page.guard';
import { ContentResolver } from '../resolvers/content.resolver';
import { PostCategoryResolver } from '../resolvers/post-category.resolver';
import { PostCollectionResolver } from '../resolvers/post-collection.resolver';
import { PostResolver } from '../resolvers/post.resolver';
import { ProductCategoryResolver } from '../resolvers/product-category.resolver';
import { ProductCollectionResolver } from '../resolvers/product-collection.resolver';
import { ProductResolver } from '../resolvers/product.resolver';
import { ComponentResolver } from '@murdough-solutions/cms-client';


interface RouteObject {
    route: string[];
}

interface PathObject {
    path: string;
}

interface BuildRouteOptions {
    include_prefix?: string;
    exclude_prefixes?: string[];
    component_resolver?: ComponentResolver
}

@Injectable()
export class RouteBuilderService {

    constructor(@Inject(WEBSITE_DOMAIN) private readonly domain: WebsiteDomainModel) {

    }

    private resolve_component(options: BuildRouteOptions, template_id: string): Type<any> | undefined
    {
        const component = options.component_resolver ? options.component_resolver(template_id) : undefined

        return component ?? component_resolver(template_id)
    }

    private filter_routes<T>(options: BuildRouteOptions, input: (T & RouteObject)[]): (T & PathObject)[] {
        const exclude_prefixes = options.exclude_prefixes ?? [];

        let pages = [...input];

        if (options.include_prefix) {
            pages = pages.filter(page => page.route[0] == options.include_prefix);
        }
        if (exclude_prefixes.length > 0) {
            pages = pages.filter(page => !exclude_prefixes.includes(page.route[0]));
        }

        return pages.map(page => {
            const route = [...page.route];
            if (options.include_prefix) {
                route.shift();
            }
            return {
                ...page,
                path: route.map(str => str.replace(/^\/+|\/+$/g, '')).join('/')
            };
        });
    }

    public BuildRoutes(options: BuildRouteOptions): Routes {
        const routes: Routes = [];

        for (const page of this.filter_routes<PageModel>(options, this.domain.website.pages)) {
            const component = this.resolve_component(options, page.template_id)

            if (component) {
                const route: Route = {
                    path: page.path,
                    component,
                    data: {
                        page_model: page,
                        content_id: page.content_id
                    },
                    resolve: {
                        content_model: ContentResolver
                    },
                    canActivate: [PageGuard]
                };
                routes.push(route);
            }
        }

        // posts
        for (const post_collection of this.filter_routes<PostCollectionModel>(options, this.domain.website.post_collections)) {
            const collection_component = this.resolve_component(options, post_collection.template_id)

            if (collection_component) {
                const route: Route = {
                    path: post_collection.path,
                    component: collection_component,
                    data: {
                        post_collection_id: post_collection.post_collection_id,
                        content_id: post_collection.content_id
                    },
                    resolve: {
                        post_collection_model: PostCollectionResolver,
                        content_model: ContentResolver
                    }
                };
                routes.push(route);
            }

            for (const category of this.filter_routes<PostCategoryModel>(options, post_collection.categories)) {
                const category_component = this.resolve_component(options, category.template_id)

                if (category_component) {
                    const route: Route = {
                        path: category.path,
                        component: category_component,
                        data: {
                            post_collection_id: post_collection.post_collection_id,
                            post_category_id: category.post_category_id,
                            content_id: category.content_id
                        },
                        resolve: {
                            post_collection_model: PostCollectionResolver,
                            post_category_model: PostCategoryResolver,
                            content_model: ContentResolver
                        }
                    };
                    routes.push(route);
                }
            }

            for (const post of this.filter_routes<PostModel>(options, post_collection.posts)) {
                const post_component = this.resolve_component(options, post.template_id)

                if (post_component) {
                    const route: Route = {
                        path: post.path,
                        component: post_component,
                        data: {
                            post_collection_id: post_collection.post_collection_id,
                            post_id: post.post_id,
                            content_id: post.content_id
                        },
                        resolve: {
                            post_collection_model: PostCollectionResolver,
                            post_model: PostResolver,
                            content_model: ContentResolver
                        }
                    };
                    routes.push(route);
                }
            }
        }


        // products
        for (const product_collection of this.filter_routes<ProductCollectionModel>(options, this.domain.website.product_collections)) {
            const collection_component = this.resolve_component(options, product_collection.template_id)

            if (collection_component) {
                const route: Route = {
                    path: product_collection.path,
                    component: collection_component,
                    data: {
                        product_collection_id: product_collection.product_collection_id,
                        content_id: product_collection.content_id
                    },
                    resolve: {
                        product_collection_model: ProductCollectionResolver,
                        content_model: ContentResolver
                    }
                };
                routes.push(route);
            }

            for (const category of this.filter_routes<ProductCategoryModel>(options, product_collection.categories)) {
                const category_component = this.resolve_component(options, category.template_id)

                if (category_component) {
                    const route: Route = {
                        path: category.path,
                        component: category_component,
                        data: {
                            product_collection_id: product_collection.product_collection_id,
                            product_category_id: category.product_category_id,
                            content_id: category.content_id
                        },
                        resolve: {
                            product_collection_model: ProductCollectionResolver,
                            product_category_model: ProductCategoryResolver,
                            content_model: ContentResolver
                        }
                    };
                    routes.push(route);
                }
            }

            for (const product of this.filter_routes<ProductModel>(options, product_collection.products)) {
                const product_component = this.resolve_component(options, product.template_id)

                if (product_component) {
                    const route: Route = {
                        path: product.path,
                        component: product_component,
                        data: {
                            product_collection_id: product_collection.product_collection_id,
                            product_id: product.product_id,
                            content_id: product.content_id
                        },
                        resolve: {
                            product_collection_model: ProductCollectionResolver,
                            product_model: ProductResolver,
                            content_model: ContentResolver
                        }
                    };
                    routes.push(route);
                }
            }

        }



        routes.push({
            path: '**',
            redirectTo: '/not-found'
        });
        return routes;
    }
}
