import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class EventService {

    constructor(private readonly snackBar: MatSnackBar) {

    }


    actionSuccess(message = 'Submit Successful') {
        this.snackBar.open(message, '', {
            duration: 2000,
        });
    }

    actionError(message: string) {
        this.snackBar.open(message, '', {
            duration: 2000,

        });
    }

    actionIncomplete(message = 'Please complete all required fields.') {
        this.actionError(message)
    }

}
