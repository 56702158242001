<ng-template #campsTemplate let-products="products">
    <ngb-carousel *ngIf="products" [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="10000">
        <ng-template ngbSlide *ngFor="let product_group of products | chunk: 3">
            <div class="slide-wrapper">
                <div class="row">
                    <div class="col-sm-4" *ngFor="let product of product_group">
                        <div class="card mb-3">
                            <a class="card-image" *ngIf="product.properties['list_image']?.asset as list_image"
                                [style.background-image]="'url('+cdn.getAssetSmallUrl(list_image)+')'"
                                [routerLink]="product.route"></a>
                            <div class="card-body">
                                <h5 class="text-center">
                                    <a [routerLink]="product.route">{{ product.name }}</a>
                                </h5>
                                <cms-template-section [product]="product"
                                    id="e6c65958-4764-4e9d-b597-8bb06632737a"></cms-template-section>
                                <p class="mb-0">
                                    <a class="float-right me-2" [routerLink]="product.route">Learn More &gt;&gt;</a>
                                    <span *ngFor="let category of product.categories">
                                        <img *ngIf="category.properties['difficulty_image']?.asset as difficulty_image"
                                            [src]="cdn.getAssetSmallUrl(difficulty_image)" height="12px" width="auto" />
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
</ng-template>

<ng-container *ngIf="product_collection$ | async as product_collection">
    <ul ngbNav #nav="ngbNav" class="nav justify-content-center mb-3">
        <li ngbNavItem class="nav-item" [class.active]="!current_product_category">
            <a ngbNavLink class="nav-link" (click)="loadProductCategory(undefined)">All Levels</a>
            <ng-template ngbNavContent>
                <ng-container
                    *ngTemplateOutlet="campsTemplate;context:{ products: product_collection.products }"></ng-container>

            </ng-template>
        </li>
        <li ngbNavItem *ngFor="let category of product_collection.categories | orderBy:'position'" class="nav-item"
            [class.active]="current_product_category?.product_category_id == category.product_category_id">
            <a ngbNavLink class="nav-link" (click)="loadProductCategory(category)">
                <img *ngIf="category.properties['difficulty_image']?.asset as difficulty_image"
                    [src]="cdn.getAssetUrl(difficulty_image)" height="12px" width="auto" />
                <span class="ms-2">{{ category.name }}</span>
            </a>
            <ng-template ngbNavContent>
                <ng-container
                    *ngTemplateOutlet="campsTemplate;context:{ products: products_map[category.product_category_id] }"></ng-container>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <div class="slide-wrapper">
        <p class="text-end">
            <a [routerLink]="product_collection.route" class="see-all-camps">See All Camps &gt;&gt;</a>
        </p>
    </div>
</ng-container>