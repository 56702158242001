<ng-container *ngIf="content$ | async as content">
    <div class="container page-banner dark-banner">
        <ng-container *ngIf="content.properties['banner_image']?.asset as banner_image">
            <img class="banner-image d-block d-lg-none" [src]="cdn.getAssetMediumUrl(banner_image)" />
            <img class="banner-image d-none d-lg-block" [src]="cdn.getAssetUrl(banner_image)" />
        </ng-container>
        <div class="row">
            <div class="col-md-7">
                <div class="banner-content">
                    <cms-template-section id="7d4c1409-4c72-4caf-b24d-b715a399373c"></cms-template-section>

                    <p *ngIf="content.properties['schedule_page']?.page as schedule_page" class="mt-3">
                        <a class="btn btn-primary" [routerLink]="schedule_page.route">Talk About FAST With Us</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container bg-white pt-5 pb-5">
        <div class="content-wrapper mt-3">
            
            <cms-template-section id="4f067034-db89-488b-a7b1-e4b6021e294a"></cms-template-section>

        </div>
        
        <div class="cta-wrapper mt-5 mb-5">
            <cms-template-section id="78914bc4-2f60-45ba-a1fa-71dbfe82c888"></cms-template-section>

            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call</a>
            </p>
        </div>
        
        <!-- Second -->
        <div class="content-wrapper mb-5">
            <cms-template-section id="32ecd6f8-cc49-4865-b322-1f3ca4b3924c"></cms-template-section>
        </div>
        
        <div class="row mb-5">
            <div class="col-md-7 second-left-image">
                <img src="https://d2pfoxivue2zy1.cloudfront.net/cdn/assets/812d0030-625f-47b3-b48f-36b762ac0d36.jpg" class="img-fluid mb-3" />
            </div>
            <div class="col-md-5">
                <div class="second-right-wrapper">
                    <cms-template-section id="e9e83143-0ee9-4e94-9f1c-85401f98b335"></cms-template-section>
                </div>
            </div>
        </div>
        
        <div class="cta-wrapper mt-5 mb-5">
            <cms-template-section id="57be97cc-4cd0-496e-8f51-92ef341399f0"></cms-template-section>

            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call Now</a>
            </p>
        </div>
        
        <!-- Third -->
        <div class="content-wrapper mb-5">
            <cms-template-section id="b521c8c1-13cf-4e9c-bfcd-9de829a86581"></cms-template-section>
        </div>
        
                
        <div class="row mb-5">
            <div class="col-md-7 third-left-image">
                <img src="https://d2pfoxivue2zy1.cloudfront.net/cdn/assets/53668f43-ea93-4f75-abe9-6d3769056c4f.jpg" class="img-fluid mb-3" />
            </div>
            <div class="col-md-5">
                <div class="third-right-wrapper">
                    <cms-template-section id="0dcae4e0-5541-461d-968c-567b269f75b7"></cms-template-section>
                </div>
            </div>
        </div>
                
        <div class="content-wrapper mb-5">
            <cms-template-section id="25db36cb-db5a-4ac9-97e0-a492a1278151"></cms-template-section>
        </div>
        
        <div class="cta-wrapper mt-5 mb-5">
            <cms-template-section id="1cda8c46-7637-4b8f-bb79-3fd4a2b832cb"></cms-template-section>

            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call Now</a>
            </p>
        </div>
        
        <!-- Fourth -->
        <div class="content-wrapper mb-5">
            <cms-template-section id="82a698c4-11b6-4c30-b0cf-08099e94170c"></cms-template-section>
        </div>
        
        <div class="cta-wrapper mt-5 mb-5">
            <cms-template-section id="57c80863-2a68-48cf-80cf-e5beae50648d"></cms-template-section>

            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call Now</a>
            </p>
        </div>
        
        <!-- Testimonials -->
        <div class="content-wrapper mb-4">
            <cms-template-section id="740cd9dd-a963-4f2d-9917-4304d51d30bf"></cms-template-section>
        </div>
        
        <cms-post-collection id="0bad3464-478c-4bbb-bf01-899bc13c58af" [component_resolver]="component_resolver"></cms-post-collection>
        
        <div class="cta-wrapper mt-5">
            <cms-template-section id="9e98a1a6-389d-474b-bd15-5d0a1665f23b"></cms-template-section>

            <p class="text-center mt-3" *ngIf="content.properties['schedule_page']?.page as schedule_page">
                <a class="btn btn-primary" [routerLink]="schedule_page.route">Schedule a Call Now</a>
            </p>
        </div>
    </div>
    
</ng-container>