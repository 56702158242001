import { Component, Inject } from '@angular/core';
import { CDNService, PostCollectionModel } from '@murdough-solutions/cms-common';
import { POST_COLLECTION_MODEL } from '@murdough-solutions/cms-client';

@Component({
  selector: 'CustomTestimonialsCarousel',
  templateUrl: './testimonials-carousel.html',
  styleUrls: ['./testimonials-carousel.scss']
})
export class CustomTestimonialsCarousel {

  constructor(
    @Inject(POST_COLLECTION_MODEL) public readonly post_collection: PostCollectionModel,
    public readonly cdn: CDNService
  ) {

  }

}
