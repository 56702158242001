import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CDNService, ProductCategoryModel } from '@murdough-solutions/cms-common';
import { Observable } from 'rxjs';

@Injectable()
export class ProductCategoryResolver  {

    constructor(private cdn: CDNService)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<ProductCategoryModel> {
        return this.cdn.GetProductCategory(route.data.product_category_id);
    }
}
