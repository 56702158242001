import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, take, throwError } from "rxjs";
import { environment } from 'src/environments/environment';
import { EventService } from "./event.service";

@Injectable()
export class ApiService {
    constructor(
        private readonly client: HttpClient,
        private readonly event: EventService
    ) {

    }

    public get<TReturn>(path: string[], opts?: {
        params?: unknown
    }): Observable<TReturn> {
        const params = new HttpParams({ fromObject: opts?.params ? { ...opts.params } : undefined })

        return this.client.get<TReturn>(`${environment.service_base}api/v1/${path.join('/')}`, { params }).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => {
                const message = err.error?.message ?? err.message
                this.event.actionError(message)
                return throwError(() => message);
            })
        )
    }

    public post<TReturn, TPayload>(path: string[], payload: TPayload): Observable<TReturn> {
        return this.client.post<TReturn>(`${environment.service_base}api/v1/${path.join('/')}`, payload).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => {
                const message = err.error?.message ?? err.message
                this.event.actionError(message)
                return throwError(() => message);
            })
        )
    }

    public put<TReturn, TPayload>(path: string[], payload: TPayload): Observable<TReturn> {
        return this.client.put<TReturn>(`${environment.service_base}api/v1/${path.join('/')}`, payload).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => {
                const message = err.error?.message ?? err.message
                this.event.actionError(message)
                return throwError(() => message);
            })
        )
    }

    public delete<TReturn>(path: string[]): Observable<TReturn> {
        return this.client.delete<TReturn>(`${environment.service_base}api/v1/${path.join('/')}`).pipe(
            take(1),
            catchError((err: HttpErrorResponse) => {
                const message = err.error?.message ?? err.message
                this.event.actionError(message)
                return throwError(() => message);
            })
        )
    }

}