<ng-container *ngIf="page$ | async as page">
    <div class="container bg-white h-100" *ngIf="content$ | async as content">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li *ngFor="let p of cdn.GetPageHeirarchy(page)" class="breadcrumb-item"><a [routerLink]="p.route">{{ p.name }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ page.name }}</li>
            </ol>
        </nav>
        
        <cms-template-section id="82e41ce9-6525-429b-b69a-ad3317e8db60"></cms-template-section>
        
        <p *ngIf="cdn.GetParentPage(page) as parent" class="mt-4">
            <a [routerLink]="parent.route">&lt; Return to {{ parent.name }}</a>
        </p>
    </div>
</ng-container>