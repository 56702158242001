import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CDNService, ContentModel, IdentityService, PageModel } from '@murdough-solutions/cms-common';
import { map } from 'rxjs/operators';
import { component_resolver } from 'src/app/component-map';

@Component({
    selector: 'CustomSparksStoryPage',
    templateUrl: './sparks-story-page.html',
    styleUrls: ['./sparks-story-page.scss']
})
export class CustomSparksStoryPage {

    public readonly component_resolver = component_resolver

    constructor(
        private readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly cdn: CDNService,
        public readonly identity: IdentityService
    ) {

    }

    public readonly page$ = this.route.data.pipe(
        map(({ page_model }) => page_model as PageModel)
    );

    public readonly content$ = this.route.data.pipe(
        map(({ content_model }) => content_model as ContentModel)
    );

    scroll(el: HTMLElement): void {
        el.scrollIntoView({ behavior: 'smooth' });
    }
}
